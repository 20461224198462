
import { defineComponent } from "vue";

export default defineComponent({
  name: "MyMHTSection",
  data() {
    return {
      dataNames: {
        myMHT: "My MHT",
      },
    };
  },
  components: {},
  mounted() {
  },
  methods: {
  },
});
